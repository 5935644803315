<template>
  <div class="CmsContentsFiledsList">
    <div class="contentsWidth mx-auto mt-4">

      <div class="">
        <p class="title mb-0">登録大学 - 一覧</p>
        <hr class="title">
      </div>

      <div id="serchWrap" class="mt-4">
        <div class="bold">絞り込み条件</div>
        <div class="search_area flex flexCenter">
          <div class="searchMiddle inlineBlock searchSpace">
            <div>大学名</div>
            <div>
              <b-form-input
                v-model="search.name"
                placeholder="入力してください。"/>
            </div>
          </div>
          <div class="searchMiddleLong inlineBlock searchSpace">
            <div>メールアドレス</div>
            <div>
              <b-form-input
                v-model="search.mail_address"
                placeholder="入力してください。"/>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>プログラム割当</div>
            <div>
              <b-form-select
                v-model="search.series"
                :options="seriesList">
                <template v-slot:first>
                  <option :value="null">-- 選択してください。 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
        </div>
      </div>

      <div class="contentsWidth mt-2">
        <div class="flex flex-between my-2">
          <b-pagination
            class="mb-1"
            aria-controls="fieldTable"
            :value="currentPage"
            @input="setCurrentPage($event)"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                :value="perPage"
                @change="setPerPage"
                :options="pageOptions"
              />
            </div>
            <div class="ml-4 inlineBlock">
              <b-link
                to="/cms/university/create">新規登録</b-link>
            </div>
          </div>
        </div>

        <b-table striped hover
          id='fieldTable'
          table-class="cmsUniversityTable"
          thead-class="tableHead"
          tbody-tr-class="dataWrap"
          :items="universityList"
          :fields="header"
          :filter="search"
          :filter-function="filtering"
          @filtered="onFiltered"
          show-empty
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template v-slot:emptyfiltered="scope">
            <div class="flex flexCenter">条件に一致するデータがありません。</div>
          </template>
          <template
            v-slot:head(checkbox)>
            <b-link
              id="popover"
              class="ml-1 select-pop-over-trigger">選択</b-link>
            <b-popover
              target="popover"
              title=""
              triggers="hover"
              placement="right"
            >
              <template v-slot:default>
                <p class="mb-0 select-pop-over" @click="pageSelect">ページ内全選択</p>
                <p class="mb-0 select-pop-over" @click="pageRemove">ページ内全解除</p>
                <p class="mb-0 select-pop-over" @click="allSelect">全選択</p>
                <p class="mb-0 select-pop-over" @click="allRemove">全解除</p>
              </template>
            </b-popover>
          </template>
          <template v-slot:cell(checkbox)="row">
            <div class="flex flexCenter">
              <b-form-checkbox
                @change="updateSelectedIdList($event)"
                :checked="selectedIdList"
                :value="row.item.id"
                />
            </div>
          </template>
          <template v-slot:cell(btn)="row">
            <b-link :to="'/cms/university/edit/'+row.item.id">
              詳細
            </b-link>
          </template>
        </b-table>

        <div class="flex flex-between my-2" v-if="totalRows > 0">
          <b-pagination
            class="mb-1"
            aria-controls="fieldTable"
            :value="currentPage"
            @input="setCurrentPage($event)"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                :value="perPage"
                @change="setPerPage"
                :options="pageOptions"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottonArea flex flexCenter mt-5">
      <b-button class="btn btn-lg mr-2 bold" to="/cms/top">トップに戻る</b-button>
      <b-button
        variant="primary"
        class="btn btn-primary btn-lg mr-2 bold ml-2"
        :disabled="this.selectedIdList.length < 1"
        to="/cms/university/mail">メール送信</b-button>
    </div>
  </div>
</template>

<script>
import api from '@/modules/api';

export default {
  name: 'CmsUniversityList',
  data() {
    return {
      header: [
        { key: 'checkbox', label: '', sortable: false },
        {
          key: 'name',
          label: '大学名',
          class: 'university-th',
          sortable: true,
        },
        {
          key: 'busyo',
          label: '部署等',
          class: 'busyo-th',
          sortable: true,
        },
        {
          key: 'mail_address',
          label: 'メールアドレス',
          class: 'mail-th',
          sortable: true,
        },
        {
          key: 'seriesText',
          label: 'プログラム割当',
          class: 'busyo-th',
          sortable: true,
        },
        {
          key: 'btn',
          label: '詳細',
          class: 'btn-th',
          sortable: false,
        },
      ],
      pageOptions: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
        // { value: 0, text: 'すべて' },
      ],
      isInit: true,
      oldPage: 1,
      rawUniversityList: [],
      rawSeriesList: [],
    };
  },
  methods: {
    // フィルター用 search変更時に各行に対して走る
    filtering(lineData, search) {
      const nameSort = search.name === '' || lineData.name.includes(search.name);
      const mailSort = search.mail_address === '' || lineData.mail_address.includes(search.mail_address);
      const seriesSort = search.series === null || lineData.series.includes(search.series);
      return nameSort && mailSort && seriesSort;
    },
    onFiltered(filteredItems) {
      this.$store.commit('cmsUniversitySearch/setTotalRows', filteredItems.length);
      if (this.isInit) {
        this.$store.commit('cmsUniversitySearch/setCurrentPage', this.oldPage);
      } else {
        this.$store.commit('cmsUniversitySearch/setCurrentPage', 1);
      }
      this.$store.commit('cmsUniversitySearch/setFilterdIdList', filteredItems);
    },
    async initFetch() {
      const response = await api.send('/api/cms/university/list')
        .catch((err) => {
          console.log(err);
        });
      this.rawUniversityList = response.data.list;
      this.rawSeriesList = response.data.seriesList;
      this.$store.commit('cmsUniversitySearch/setTotalRows', this.rawUniversityList.length);
      this.$store.commit('cmsUniversitySearch/setFilterdIdList', this.rawUniversityList);
    },
    allSelect() {
      this.$store.commit('cmsUniversitySearch/allSelectedId');
    },
    allRemove() {
      this.$store.commit('cmsUniversitySearch/initSelectedId');
    },
    pageSelect() {
      const start = (this.currentPage - 1) * (this.perPage);
      const end = this.currentPage * this.perPage;
      const idListOnPage = this.filterdIdList.slice(start, end);
      idListOnPage.forEach((id) => {
        this.$store.commit('cmsUniversitySearch/addSelectedId', id);
      });
    },
    pageRemove() {
      const start = (this.currentPage - 1) * (this.perPage);
      const end = this.currentPage * this.perPage;
      const idListOnPage = this.filterdIdList.slice(start, end);
      idListOnPage.forEach((id) => {
        this.$store.commit('cmsUniversitySearch/removeSelectedId', id);
      });
    },
    updateSelectedIdList(list) {
      this.$store.commit('cmsUniversitySearch/setSelectedId', list);
    },
    setFilter(key, value) {
      const param = { key, value };
      this.$store.commit('cmsUniversitySearch/setFilter', param);
    },
    setCurrentPage(page) {
      this.$store.commit('cmsUniversitySearch/setCurrentPage', page);
    },
    setPerPage(page) {
      this.$store.commit('cmsUniversitySearch/setPerPage', page);
    },
    setTotalRows(value) {
      this.$store.commit('cmsUniversitySearch/setTotalRows', value);
    },
  },
  computed: {
    seriesList() {
      return this.rawSeriesList.map((series) => {
        return { value: series.id, text: series.name };
      });
    },
    seriesListViewText() {
      const viewText = {};
      this.rawSeriesList.forEach((series) => {
        // 先頭一文字を取得
        viewText[series.id] = series.name.slice(0, 1);
      });
      return viewText;
    },
    universityList() {
      return this.rawUniversityList.map((university) => {
        const idList = [];
        let seriesText = '';
        university.university_series.forEach((series) => {
          idList.push(series.series_id);
          seriesText += `${this.seriesListViewText[series.series_id]} `;
        });
        return {
          id: university.id,
          name: university.university.university_name,
          busyo: university.university.busyo,
          mail_address: university.login_id,
          series: idList,
          seriesText,
        };
      });
    },
    search() {
      return this.$store.state.cmsUniversitySearch.search;
    },
    currentPage() {
      return this.$store.state.cmsUniversitySearch.currentPage;
    },
    perPage() {
      return this.$store.state.cmsUniversitySearch.perPage;
    },
    totalRows() {
      return this.$store.state.cmsUniversitySearch.totalRows;
    },
    selectedIdList() {
      return this.$store.state.cmsUniversitySearch.selectedIdList;
    },
    filterdIdList() {
      return this.$store.state.cmsUniversitySearch.filterdIdList;
    },
    nowPageCount() {
      if (this.totalRows === 0) {
        return 0;
      }
      let maxPage = Math.floor(this.totalRows / this.perPage);
      // 上記計算にあまりがあれば、ページ数をプラス1
      const mod = this.totalRows % this.perPage;
      let lastPageItemNum;
      if (mod !== 0) {
        maxPage += 1;
        lastPageItemNum = mod;
      } else {
        lastPageItemNum = this.perPage;
      }
      // 最終ページ以外は、現在の表示件数設定の値
      if (this.currentPage < maxPage) {
        return this.perPage;
      }
      // 最終ページが表示件数ぴったりの場合以外は、端数を表示
      return lastPageItemNum;
    },
  },
  // ロード画面
  async created() {
    // this.$store.dispatch('page/onLoading');
    this.oldPage = this.currentPage;
    await this.initFetch();
    this.isInit = false;
    // this.$store.dispatch('page/offLoading');
  },
};
</script>

<style>
  .cmsUniversityTable .university-th {
    width: 250px !important;
  }
  .cmsUniversityTable .btn-th {
    width: 100px !important;
  }
</style>

<style scoped>
  #serchWrap input, #serchWrap select {
    height: 50px;
  }

  #NumberWrap {
    height: 50px;
  }

  .search_area {
    background: #DDD;
    padding: 10px;
    margin-bottom: 30px;
  }

  .searchMiddleLong {
    width: 315px;
  }

  .searchMiddle {
    width: 205px;
  }

  .searchShort {
    width: 135px;
  }

  .searchSpace {
    margin-right: 15px;
  }

  #popover {
    cursor: pointer;
    color: #FFF !important;
    text-decoration: underline;
  }

  .link {
    color: #0A8EA7 !important;
    cursor: pointer;
  }

  .link:hover {
    text-decoration: underline;
  }

  .err-wrap>p{
    color: #dc3545;
  }
</style>
